<template>
  <div id="deliverApp">
    <img class="logo" src="@/assets/logo.png" @click="selectedState = null" alt="">
    <div v-if="orderId == null">
      No order ID provided, please scan QR code again.
    </div>
    <div v-else-if="completed">
      Shipment status of the order is successfully updated.
      <span class="arabic">تم تحديث حالة الشحن الخاصة بالطلب بنجاح</span>
      <a id="resetStateBtn" @click="completed = false">
        Update status again
        <span class="arabic">الرجاء تحديث الحالة مرة أخرى</span>
      </a>
    </div>
    <div v-else-if="selectedState == null">
      <p>
        Please amend the shipment status by clicking one of the buttons below.
        <span class="arabic">يرجى تعديل حالة الشحنة بالنقر فوق أحد الأزرار أدناه</span>
      </p>
      <ul id="orderStates">
        <li v-for="deliverState in deliverStates" :key="states[deliverState].name"
          @click="selectedState = states[deliverState]">
          <img v-if="states[deliverState].image" :src="states[deliverState].image" :alt="states[deliverState].text">
          {{ states[deliverState].text }}
          <span class="arabic">{{ states[deliverState].textArabic }}</span>
        </li>
      </ul>
    </div>
    <ul id="selectedState" v-else>
      Selected order status:
      <span class="arabic">حالة الطلب المحددة</span>
      <li id="currentState">
        <img :src="selectedState.image" alt="">
        {{ selectedState.text }}
      </li>
      <li id="cancelState" @click="selectedState = null">
        Change selected status
        <span class="arabic">تغيير الحالة المختارة</span>
      </li>
      <li id="pincodeSection">
        <p>
          Enter pincode below to amend the shipment status of this order.
          <span class="arabic">أدخل الرمز السري أدناه لتعديل حالة الشحن لهذا الطلب</span>
        </p>
        <span class="error-message" v-show="errorMessage">
          - {{ errorMessage }}
        </span>
        <form @submit.prevent="updateState">
          <input type="number" v-model="pincode" placeholder="pincode">
          <button type="submit">
            <img class="loader" v-if="isLoading" src="@/assets/loader.svg" />
            <span v-else>Update status</span>
          </button>
        </form>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import {
  cashReceivedByDistributor,
  cashReceivedFromCostumer,
  parcelAtDistributionCenter,
  parcelDelivered,
  parcelNotPickedUpAtVendor,
  parcelPickedUp,
  parcelUndeliverable,
  returnParcelAtDistributionCenter,
  returnParcelDelivered,
  returnParcelPickedUp
} from './imageExporter';

export default {
  data() {
    return {
      urlParams: new URLSearchParams(window.location.search),
      completed: false,
      selectedState: null,
      isLoading: false,
      errorMessage: '',
      pincode: '',
      states: {
        parcelPickedUp: {
          name: '1',
          image: parcelPickedUp,
          text: 'Parcel picked up',
          textArabic: ' تم استلام الطرود'
        },
        parcelAtDistributionCenter: {
          name: '2',
          image: parcelAtDistributionCenter,
          text: 'Parcel at distribution center',
          textArabic: 'طرد في مركز التوزيع'
        },
        parcelDelivered: {
          name: '3',
          image: parcelDelivered,
          text: 'Parcel delivered',
          textArabic: 'تم تسليم الطرد'
        },
        parcelUndeliverable: {
          name: '4',
          image: parcelUndeliverable,
          text: 'Parcel undeliverable',
          textArabic: 'لم يتم تسليم الطرد'
        },
        parcelNotPickedUpAtVendor: {
          name: '5',
          image: parcelNotPickedUpAtVendor,
          text: 'Parcel not picked up at vendor',
          textArabic: 'لم يتم استلام الطرد من البائع'
        },
        registerReturnParcelPickedUp: {
          name: '6',
          image: returnParcelPickedUp,
          text: 'Return parcel picked up',
          textArabic: 'تم استلام الطرد المرتجع'
        },
        registerReturnParcelAtDistributionCenter: {
          name: '7',
          image: returnParcelAtDistributionCenter,
          text: 'Return parcel at distribution center',
          textArabic: 'تم اعادة الطرد الى مركز التوزيع'
        },
        registerReturnParcelDelivered: {
          name: '8',
          image: returnParcelDelivered,
          text: 'Return parcel delivered',
          textArabic: 'تم تسليم الطرد المرتجع'
        },
        registerCashReceivedFromCustomer: {
          name: '9',
          image: cashReceivedFromCostumer,
          text: 'Cash received from customer',
          textArabic: 'تم استلام المبالغ النقدية الواردة من الزبون'
        },
        registerCashReceivedByDistributor: {
          name: '10',
          image: cashReceivedByDistributor,
          text: 'Cash received by distributor',
          textArabic: ' تم استلام المبالغ النقدية المستلمة من قبل الموزع'
        }
      }
    };
  },
  computed: {
    orderId() {
      return this.urlParams.get('order_id');
    },
    deliverStates() {
      return Object.keys(this.states);
    }
  },
  watch: {
  },
  methods: {
    updateState() {
      let self = this
      self.isLoading = true;
      self.errorMessage = ''

      axios({
        method: 'put',
        url: 'https://qr.trone-live.nl/state',
        data: {
          pincode: this.pincode,
          orderId: this.orderId,
          state: this.selectedState.name
        }
      }).then(() => {
        self.selectedState = null;
        self.completed = true;
      }).catch(error => {
        if (error.response.status === 401) {
          self.errorMessage = "Invalid pincode"
        } else if (error.response.status === 422) {
          self.errorMessage = "Invalid order ID"
        } else {
          self.errorMessage = "Somehting went wrong"
        }
      }).finally(() => this.isLoading = false);

    }
  },
  created() {
  }
}
</script>

<style lang="scss">
body {
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  font-family: 'Roboto', sans-serif;
  margin: 0;
  overflow-x: hidden;
}

.arabic {
  direction: rtl;
  display: block;
  text-align: right;
}

#deliverApp {
  margin: 0 auto;
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
  max-width: 450px;

  .logo {
    width: 100%;
    cursor: pointer;
  }

  #selectedState {
    list-style-type: none;
    padding: 0;

    li {
      padding: 5px;
      box-sizing: border-box;

      &#currentState {
        border: 1px solid rgba(0, 0, 0, 0.2);
        display: grid;
        grid-template-columns: 100px 1fr;
        align-items: center;
        grid-column-gap: 20px;

        img {
          width: 100%;
        }
      }

      &#cancelState {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  #orderStates {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    list-style-type: none;
    padding: 0;
    margin: 0 auto;

    li {
      display: grid;
      grid-template-rows: auto auto;
      grid-row-gap: 10px;
      align-items: center;
      text-align: center;
      background-color: #f7f7f7;
      padding: 20px;
      box-sizing: border-box;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
      cursor: pointer;

      img {
        max-width: 100px;
        margin: 0 auto;
      }

      &:active {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      }
    }
  }

  #resetStateBtn {
    text-decoration: underline;
    cursor: pointer;
    display: block;
    margin-top: 20px;
  }

  #pincodeSection {
    input {
      box-sizing: border-box;
      font-size: 16px;
      padding: 10px;
      width: 100%;
      border: none;
      border-bottom: 2px solid rgba(0, 0, 0, 0.9);
    }

    button {
      width: 100%;
      padding: 15px 20px;
      margin-top: 20px;
      background-color: #000000;
      color: white;
      border: none;
      border-radius: 20px;
      position: relative;
      height: 45px;

      .loader {
        height: 35px;
        position: absolute;
        top: 5px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }

  .error-message {
    color: red;
  }
}</style>
